import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import { Html, SEO } from '../components'
import PageHeader from '../components/page-header'

const KampanyaTemplate = ({
  data: {
    post: { frontmatter, html, timeToRead }
  }
}) => {
  return (
    <Layout>
      <SEO
        title={frontmatter.title}
        description={frontmatter.summary}
        isBlogPost
        datePublished={frontmatter.date}
      />
      <PageHeader>
        <h1>{frontmatter.title}</h1>
        <p>{frontmatter.summary}</p>
      </PageHeader>
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <Img
                className="mb-3 img-fluid"
                fluid={frontmatter.featuredImage.childImageSharp.fluid}
              />
            </div>
            <div className="col-md-6">
              <Html>{frontmatter.content}</Html>
              <div dangerouslySetInnerHTML={{ __html: html }} />
            </div>
          </div>{' '}
          <Link to="..">Anasayfaya dön</Link>
        </div>
      </div>
    </Layout>
  )
}

export default KampanyaTemplate

export const pageQuery = graphql`
  query PostBySlug2($slug: String!) {
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      frontmatter {
        title
        content
        summary
        date
        featuredImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
